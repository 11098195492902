import React, { useState } from "react"
import { VscPersonAdd } from "react-icons/vsc"
import { VscCalendar } from "react-icons/vsc"
import "./docheader.css"
import { DateRange } from "react-date-range"
import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file
import { format } from "date-fns"
import { navigate } from "gatsby"

const DocHeader = ({ type }) => {
  const [doctor, setDoctor] = useState("")
  const [specialty, setSpecialty] = useState("")
  const [openDate, setOpenDate] = useState(false)
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ])

  const handleSearch = () => {
    navigate("/Doctors", { state: { doctor, specialty, date } })
  }

  return (
    <div className="docheader">
      <div
        className={
          type === "list" ? "headerContainer listMode" : "headerContainer"
        }
      >
        {type !== "list" && (
          <>
            <h1 className="headerTitle">Find a Doctor Near you</h1>
            <p className="headerDesc">
              Enjoy online access of certified doctors near you
            </p>
            <div className="headerSearch">
              <div className="headerSearchItem">
                <VscPersonAdd className="headerIcon" />
                <input
                  type="text"
                  placeholder="Doctor's Name"
                  className="headerSearchInput"
                  onChange={e => setDoctor(e.target.value)}
                />
              </div>
              <div className="headerSearchItem">
                <VscPersonAdd className="headerIcon" />
                <input
                  type="text"
                  placeholder="Specialty"
                  className="headerSearchInput"
                  onChange={e => setSpecialty(e.target.value)}
                />
              </div>
              <div className="headerSearchItem">
                <VscCalendar className="headerIcon" />
                <span
                  onClick={() => setOpenDate(!openDate)}
                  className="headerSearchText"
                >{`${format(date[0].startDate, "MM/dd/yyyy")} to ${format(
                  date[0].endDate,
                  "MM/dd/yyyy"
                )}`}</span>
                {openDate && (
                  <DateRange
                    editableDateInputs={true}
                    onChange={item => setDate([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={date}
                    className="date"
                    minDate={new Date()}
                  />
                )}
              </div>

              <div className="headerSearchItem">
                <button className="headerBtn" onClick={handleSearch}>
                  Search
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default DocHeader
