import React from "react"
import styled from "styled-components"
//import PrimaryCare from "../../images/primary-care.svg"
const specialty = [
  {
    id: 1,
    //image: PrimaryCare,
    title: "Primary care",
  },
  {
    id: 2,
    //image: PrimaryCare,
    title: "Primary care",
  },
  {
    id: 3,
    //image: PrimaryCare,
    title: "Primary care",
  },
  {
    id: 4,
    //image: PrimaryCare,
    title: "Primary care",
  },
  {
    id: 5,
    //image: PrimaryCare,
    title: "Primary care",
  },
  {
    id: 6,
    //image: PrimaryCare,
    title: "Primary care",
  },
]
const SpecialtyCard = styled.div`
  flex: 1 0 15%;
  @media screen and (min-width: 1024px) {
    margin-left: 48px;
  }
  @media screen and (min-width: 768px) {
    margin-left: 24px;
    flex-basis: 0px;
  }
  @media screen and (min-width: 1280px) {
    margin-left: 36px;
  }
  @media screen and (min-width: 1440px) {
    margin-left: 48px;
  }
`
const SpecialtyWrapper = styled.a`
  border-radius: 5px;
  border: 1px solid rgb(223, 225, 229);
  display: block;

  position: relative;
  transition: box-shadow 0.2s ease 0s, border 0.2s ease 0s;
  :hover {
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 2px;
    border: 1px solid rgb(223, 225, 229);
  }
`
const SpecialtyInner = styled.span`
  -webkit-box-align: center;
  align-items: center;
  inset: 0px;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
`
const SpecialtyName = styled.p`
  margin-top: 12%;
  text-align: center;
  color: black;
`
export default function Speciality() {
  return (
    <>
      {specialty.map(item => {
        const { id, image, title } = item
        return (
          <SpecialtyCard key={id}>
            <SpecialtyWrapper href="#">
              <SpecialtyInner>
                <img src={image} alt="" />
                <SpecialtyName>{title}</SpecialtyName>
              </SpecialtyInner>
            </SpecialtyWrapper>
          </SpecialtyCard>
        )
      })}
    </>
  )
}
