import React, { useState } from "react"
import ProfileMenu from "./menuPopup/AccountMenu"
import { alpha, makeStyles } from "@material-ui/core"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Badge from "@material-ui/core/Badge"
import AppBar from "@material-ui/core/AppBar"
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined"
import { Link } from "gatsby"
import { IconContainer, TopButton } from "./topNavElements"
import useCartState from "../../stores/cart"

const useStyles = makeStyles(theme => ({
  appBar: {
    height: "80px",
    backgroundColor: " #edfff3cc",
    position: "sticky",
    top: "0",
    zIndex: "10",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  logoLg: {
    display: "none",
    color: "#1d9682cc",
    fontFamily: "oswald",
    textDecoration: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  logoSm: {
    display: "block",
    color: "#1d9682cc",
    fontFamily: "oswald",
    textDecoration: "none",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  searchContainer: {
    width: "40%",
    height: "45px",
    display: "flex",
    borderRadius: "4px",
    alignItems: "center",
    justifyContent: "center",
    background: "Linear-gradient(to right, #007965, #05FFD6)",
  },
  search: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    margin: "5px",
    borderRadius: "4px",
    color: "#1d9682",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },

    [theme.breakpoints.down("sm")]: {
      display: props => (props.open ? "flex" : "none"),
      width: "70%",
    },
  },
  input: {
    color: "#1d9682",
    fontFamily: "oswald",
    marginLeft: theme.spacing(1),
  },
  cancel: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  searchButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  icons: {
    alignItems: "center",
    display: props => (props.open ? "none" : "flex"),
  },
  badge: {
    marginRight: theme.spacing(2),
  },
  icon: {
    color: "white",
    cursor: "pointer",
    "&:hover": {
      color: "#1d9682",
      transition: "all 0.2s ease-in-out",
    },
  },
  avatar: {
    cursor: "pointer",
  },
}))

export default function TopNav({ toggle }) {
  const [open, setOpen] = useState(false)
  const classes = useStyles({ open })

  const { getCartLength } = useCartState()
  return (
    <AppBar position="fixed" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" className={classes.logoLg} component={"span"}>
          <Link to="/" style={{ textDecoration: "none", color: "#1d9682cc" }}>
            Afyabook
          </Link>
        </Typography>
        <Typography variant="h6" className={classes.logoSm} component={"span"}>
          <Link to="/" style={{ textDecoration: "none", color: "#1d9682cc" }}>
            Afyabook
          </Link>
        </Typography>
        <div className={classes.icons}>
          <IconContainer>
            <Badge
              badgeContent={getCartLength()}
              overlap="rectangular"
              color="secondary"
              className={classes.badge}
            >
              <TopButton to="/Cart">
                <ShoppingBagOutlinedIcon className="icon" />
              </TopButton>
            </Badge>
          </IconContainer>
          <ProfileMenu />
        </div>
      </Toolbar>
    </AppBar>
  )
}
