import styled from 'styled-components';

export const DropdownContainer = styled.div`
  width: "400px";
`;
export const SpecialityDropdown = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: "50px";
  background-color: #f4f4f4;
  border: none;

  padding: 15px;
  font-size: 1.1rem;
  gap: 0.5rem;
  cursor: pointer;

  &:hover {
    border: 1px solid #c2c2c2;
    transition: background 0.3s ease-in;
    border: none;
  }
`;
export const SearchSpeciality = styled.input.attrs({
  placeholder: 'Search by Doctor, Condition..',
})`
  width: 100%;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: #fff;
  padding: 5px 5px 0;
  margin: 0 0.7rem;
  margin-left: 1rem;
  font-size: 1.1rem;

  height: 64px;
`;
