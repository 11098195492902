import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, navigate } from 'gatsby';
import axios from 'axios';
import Hero from '../../images/uploaded_img.svg';
import {
  BottomContainer,
  CloseIcon,
  ContentWrapper,
  FindPharmBtn,
  FormContainer,
  IconCont,
  ModalDesc,
  ModalSvg,
  ModalTxt,
  StyledModal,
} from './formElements';
import AutoCompleteL from '../location-autocomplete/AutoCompleteL';

import useAuthState from '../../stores/auth';

const useStyles = makeStyles({
  formControl: {
    margin: '10px',
    minWidth: '200px',
    '@media(minWidth: 320px)': {
      width: '100%',
    },
  },
  title: {
    flexGrow: 1,
    color: '	#00b300',
    fontSize: '2em',
  },
  uploadIcon: {
    marginRight: '10px',
    width: '100%',
    '@media(minWidth: 320px)': {
      marginRight: '1em',
    },
  },
  paper: {
    padding: '20px',
    margin: 'auto',
    maxWidth: 500,
  },
  textField: {
    marginLeft: '10px',
    marginRight: '10px',
    width: 200,
  },
});
export function FindForm() {
  const { isLoggedIn } = useAuthState();

  const classes = useStyles();
  const [isPrescriptionPicked, setIsPrescriptionPicked] = React.useState(false);
  const [Specialicty, setSpecialicty] = React.useState('');
  const [show, setShow] = React.useState(false);
  const [Hospital, setHospital] = React.useState('');
  const [Doctor, setDoctor] = React.useState('');
  const [Service, setService] = React.useState('');
  const [Sopen, setSOpen] = React.useState(false);
  const [Hopen, setHOpen] = React.useState(false);
  const [Dopen, setDOpen] = React.useState(false);
  const [SEopen, setSEOpen] = React.useState(false);

  const handleShow = () => setShow(true);
  const SpecialictyhandleChange = (event) => {
    setSpecialicty(event.target.value);
  };
  const HospitalhandleChange = (event) => {
    setHospital(event.target.value);
  };
  const DoctorhandleChange = (event) => {
    setDoctor(event.target.value);
  };
  const ServicehandleChange = (event) => {
    setService(event.target.value);
  };

  const ShandleClose = () => {
    setSOpen(false);
  };

  const ShandleOpen = () => {
    setSOpen(true);
  };
  const HhandleClose = () => {
    setHOpen(false);
  };

  const HhandleOpen = () => {
    setHOpen(true);
  };
  const DhandleClose = () => {
    setDOpen(false);
  };

  const DhandleOpen = () => {
    setDOpen(true);
  };
  const SEhandleClose = () => {
    setSEOpen(false);
  };

  const SEhandleOpen = () => {
    setSEOpen(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const [allShops, setAllShops] = React.useState([]);

  const fetchAllShops = async () => {
    const results = await axios.get(`${process.env.GATSBY_API_URL}/shops`);
    setAllShops(results.data);
  };

  React.useEffect(() => {
    fetchAllShops();
  }, []);
  const toUploadPage = () => {
    navigate('/upload-prescription');
  };
  return (
    <BottomContainer>
      <FormContainer>
        <AutoCompleteL />

        {/* {!isPrescriptionPicked && (
          <FindPharmBtn to="/pharmacy">
            <SearchOutlined style={{ width: "100%" }} />
          </FindPharmBtn>
        )} */}

        {isLoggedIn() && (
          <FindPharmBtn onClick={toUploadPage}>Upload</FindPharmBtn>
        )}
        {!isLoggedIn() && (
          <FindPharmBtn onClick={() => handleShow()}>Upload</FindPharmBtn>
        )}
        {!isLoggedIn() && (
          <StyledModal show={show} animation on={handleShow}>
            <ContentWrapper>
              <IconCont>
                <CloseIcon onClick={handleClose} />
              </IconCont>
              <ModalSvg src={Hero} />
              <ModalTxt>Kindly Login to Upload your prescription</ModalTxt>
              <ModalDesc>
                click The link below to Login
                {' '}
                <Link to="/Login">here</Link>
              </ModalDesc>
            </ContentWrapper>
          </StyledModal>
        )}
      </FormContainer>
    </BottomContainer>
  );
}
