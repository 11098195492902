import styled from 'styled-components';
import { Link } from 'gatsby';

export const TopButton = styled(Link)`
  background: #1d9682;
  border-radius: 50px;
  box-shadow: 0 3px 7px #a3d4cb;
  white-space: nowrap;
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 3px 6px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-align: "center";
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #f5f5f5;
    color: #205072;
  }
`;
export const MobileIcon = styled.div`
  color: #1d9268;
  @media screen and (min-width: 320px) {
    font-size: 1.4em;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
`;
export const IconContainer = styled.div``;
