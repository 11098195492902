import React from "react"
import "./topRated.css"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Next from "../../images/right-arrow.svg"
import Prev from "../../images/left-arrow.svg"

const data = [
  {
    id: 1,
    image:
      "//d1k13df5m14swc.cloudfront.net/photos/Dr-Nada-Saymeh-DO-335760-circle_medium__v1__.png",
    name: "Nada Saymeh",
    initials: "DO",
    specialty: "Primary Care Doctor",
    location: "Nairobi, Ke",
    topreview:
      " i hate going to any doctors, but i needed a very extensive medical clearance form filled out. Both docs were patient and clear",
  },
  {
    id: 2,
    image:
      "//d1k13df5m14swc.cloudfront.net/photos/Dr-Nada-Saymeh-DO-335760-circle_medium__v1__.png",
    name: "Nada Saymeh",
    initials: "DO",
    specialty: "Primary Care Doctor",
    location: "Nairobi, Ke",
    topreview:
      " i hate going to any doctors, but i needed a very extensive medical clearance form filled out. Both docs were patient and clear",
  },
  {
    id: 3,
    image:
      "//d1k13df5m14swc.cloudfront.net/photos/Dr-Nada-Saymeh-DO-335760-circle_medium__v1__.png",
    name: "Nada Saymeh",
    initials: "DO",
    specialty: "Primary Care Doctor",
    location: "Nairobi, Ke",
    topreview:
      " i hate going to any doctors, but i needed a very extensive medical clearance form filled out. Both docs were patient and clear",
  },
  {
    id: 4,
    image:
      "//d1k13df5m14swc.cloudfront.net/photos/Dr-Nada-Saymeh-DO-335760-circle_medium__v1__.png",
    name: "Nada Saymeh",
    initials: "DO",
    specialty: "Primary Care Doctor",
    location: "Nairobi, Ke",
    topreview:
      " i hate going to any doctors, but i needed a very extensive medical clearance form filled out. Both docs were patient and clear",
  },
  {
    id: 5,
    image:
      "//d1k13df5m14swc.cloudfront.net/photos/Dr-Nada-Saymeh-DO-335760-circle_medium__v1__.png",
    name: "Nada Saymeh",
    initials: "DO",
    specialty: "Primary Care Doctor",
    location: "Nairobi, Ke",
    topreview:
      " i hate going to any doctors, but i needed a very extensive medical clearance form filled out. Both docs were patient and clear",
  },
  {
    id: 6,
    image:
      "//d1k13df5m14swc.cloudfront.net/photos/Dr-Nada-Saymeh-DO-335760-circle_medium__v1__.png",
    name: "Nada Saymeh",
    initials: "DO",
    specialty: "Primary Care Doctor",
    location: "Nairobi, Ke",
    topreview:
      " i hate going to any doctors, but i needed a very extensive medical clearance form filled out. Both docs were patient and clear",
  },
]

export default function TopRated() {
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <img
      src={Prev}
      alt="prevArrow"
      {...props}
      style={{ height: "40px", width: "40px", left: "-50px" }}
    />
  )

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <img
      src={Next}
      alt="nextArrow"
      {...props}
      style={{ height: "40px", width: "40px" }}
    />
  )
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
  }
  return (
    <>
      <div>
        <Slider {...settings}>
          {data.map(item => {
            const {
              id,
              image,
              name,
              initials,
              specialty,
              location,
              topreview,
            } = item

            return (
              <div key={id}>
                <div
                  width="384"
                  height="281"
                  className="yjsoam-0 sc-1g0vy35-1 keAPoA"
                >
                  <div className="sc-1g0vy35-3 kPrgRn">
                    <div className="z88coy-5 bvNPFw">
                      <div className="z88coy-2 fIQFlP">
                        <div className="z88coy-0 hQQpkF">
                          <div>
                            <picture>
                              <img
                                alt="Dr. Nada Saymeh"
                                itemprop="image"
                                src={image}
                                loading="lazy"
                                className="z88coy-4 eDBGxN"
                              />
                            </picture>
                          </div>
                          <noscript>
                            <picture>
                              <img
                                alt="Dr. Nada Saymeh"
                                itemProp="image"
                                src="//d1k13df5m14swc.cloudfront.net/photos/Dr-Nada-Saymeh-DO-335760-circle_medium__v1__.png"
                                loading="lazy"
                                className="z88coy-4 eDBGxN"
                              />
                            </picture>
                          </noscript>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sc-1g0vy35-0 cLvhiR">
                    <div className="n5dszx-0 hibOsU">
                      <div className="n5dszx-2 fPEmza">
                        <svg
                          viewBox="0 0 43 40"
                          className="sc-14oxdvn-0 rbvlva-0 exODhX"
                        >
                          <path
                            d="M10.76,40L7.11,37.34l4.13-12.63L0.47,16.92l1.4-4.29H15.14L19.24,0h4.51l4.1,12.63H41.13l1.4,4.29L31.76,24.71l4.13,12.63L32.24,40,21.5,32.16ZM21.5,27.82l10,7.27L27.63,23.37l10-7.23H25.31L21.5,4.41,17.69,16.13H5.37l10,7.23L11.54,35.09Z"
                            fill="#00234B"
                          ></path>
                        </svg>
                        <div className="n5dszx-1 eGbcCN">
                          Highly recommended
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sc-1g0vy35-2 itzZAM">
                    <div className="sc-1g0vy35-4 kRPoIg">
                      <div>
                        <div className="sc-1ehgqiq-1 lnExmw">
                          <span itemprop="name">{name}</span>
                          <span className="sc-1ehgqiq-0 cyugKN">
                            , <span itemprop="honorificSuffix">{initials}</span>
                          </span>
                        </div>
                      </div>
                      <div className="sc-1g0vy35-5 dINQnR">{specialty}</div>
                      <div className="sc-1g0vy35-6 dcRcRW">{location}</div>
                    </div>
                  </div>
                  <div className="sc-10wddt8-0 bBsTtt">
                    <span className="sc-10wddt8-1 kMOMTA">
                      <svg
                        viewBox="0 0 51.6 43"
                        className="sc-14oxdvn-0 sc-10wddt8-6 uGsNL"
                      >
                        <svg
                          viewBox="0 0 43 40"
                          className="sc-14oxdvn-0 uxxkcb-0 kCXcGp"
                        >
                          <path
                            d="M32.2 40l-10.7-7.8L10.8 40l-3.7-2.7 4.1-12.6L.5 17l1.4-4.4h13.2L19.2 0h4.6l4.1 12.6h13.2l1.4 4.4-10.7 7.8 4.1 12.6-3.7 2.6z"
                            fill="#D03520"
                          ></path>
                        </svg>
                      </svg>
                      <span className="sc-10wddt8-2 iyhDDj">5.00</span>
                      <span className="sc-10wddt8-3 guhNEu">
                        (78
                        <span className="sc-10wddt8-3 sc-10wddt8-4 kKTGoJ">
                          {" "}
                          reviews
                        </span>
                        <span className="sc-10wddt8-3 sc-10wddt8-4 kKTGoJ">
                          )
                        </span>
                      </span>
                    </span>
                    <div className="sc-10wddt8-5 itshmR">{topreview}</div>
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </>
  )
}
