import styled from 'styled-components';
import { MdArrowForward, MdKeyboardArrowRight } from 'react-icons/md';
import {
  mobileS,
  mobileM,
  tablet,
  desktop,
  mobileL,
  desktopL,
} from '../../responsive';
import BannerBg from './sun-tornado.svg';

export const InfoContainer = styled.div`
  //background-image: url(${BannerBg});
  width: 100%;
  height: 100%;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  //z-index: 96;
  @media screen and (min-width: 320px) {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${mobileM({
    padding: '0 20px',
  })}
  @media screen and (min-width:768px) {
  }
  @media screen and (min-width: 1024px) {
    height: 70vh;
  }
  @media screen and (min-width: 1440px) {
    height: 80vh;
  }
  @media screen and (min-width: 1600px) {
    height: 60vh;
  }
  @media screen and (min-width: 1900px) {
    height: 55vh;
  }
`;
export const DocInfoContainer = styled.div`
  background-image: url(${BannerBg});
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (min-width: 320px) {
    height: 92vh;
  }
  ${mobileM({
    padding: '0 20px',
  })}
  @media screen and (min-width:768px) {
    height: 94vh;
  }
  @media screen and (min-width: 1024px) {
    height: 70vh;
  }
  @media screen and (min-width: 1440px) {
    height: 50vh;
  }
  @media screen and (min-width: 1600px) {
    height: 60vh;
  }
  @media screen and (min-width: 1900px) {
    height: 55vh;
  }
`;
export const ProvInfoContainer = styled.div`
  background-image: url(${BannerBg});
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  @media screen and (min-width: 320px) {
    //height: 92vh;
  }
  ${mobileM({
    padding: '0 20px',
  })}
  @media screen and (min-width:768px) {
    height: 70vh;
  }
  @media screen and (min-width: 1024px) {
    height: 90vh;
  }
`;
// remove the defaults row and space evenly
export const InfoWrapper = styled.div`
  align-items: center;
  ${mobileS({
    margin: '1em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  })}
  ${mobileM({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  })}
  ${tablet({
    margin: '1em',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  })}

  @media only screen and (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media only screen and (min-width: 1440px) {
    //margin: 4em 7em 0;
    justify-content: space-around;
  }
`;
export const ProvInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height:80vh;
  ${mobileS({
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    justifyContent: 'flex-start',
  })}
  ${mobileM({
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  })}
  ${tablet({
    margin: '1em',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  })}

  @media only screen and (min-width: 1024px) {
    justify-content: space-evenly;
    margin: 0;
  }
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgstart }) => (imgstart ? '\'col2 col1\'' : '\'col1 col2\'')};
  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgstart }) => (imgstart ? '\'col1\' \'col2\'' : '\'col1 col1\' \'col2 col2\'')};
  }
`;
export const Column1 = styled.div`
  padding: 0 15px;
  grid-area: col1;
  height: 330px;
  @media screen and (max-width: 768px) {
    height: 370px;
  }
`;
export const Column2 = styled.div`
  margin-bottom: 200px;
  padding: 0 15px;
  grid-area: col2;
`;
export const Top = styled.div`
  @media screen and (min-width: 320px) {
    width: 100%;
  }
  @media screen and (min-width: 768px) {
    width: 75%;
  }
  @media screen and (min-width: 1024px) {
    width: 50%;
  }
`;
export const TextWrapper = styled.div`
  max-width: 35%;
  padding: 0 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${mobileS({ maxWidth: '100%', padding: '0', margin: '1em 0 0' })}
  ${mobileM({ maxWidth: '100%', padding: '0', margin: '1em 0 0' })}
  ${mobileL({ maxWidth: '100%', padding: '0', margin: '2em 0 0' })}
  ${tablet({ margin: '1em 0 ' })}
  ${desktop({ maxWidth: '100%' })}
`;
export const TextWrapperClinic = styled.div`
  max-width: 35%;
  padding: 0 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${mobileS({ maxWidth: '100%', padding: '0', margin: '1em 0 0' })}
  ${mobileM({ maxWidth: '100%', padding: '0', margin: '1em 0 0' })}
  ${mobileL({ maxWidth: '100%', padding: '0', margin: '2em 0 0' })}
  ${tablet({ margin: '1em 0 ' })}
  ${desktop({ maxWidth: '400px' })}
`;
export const TopLine = styled.p`
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;
export const Heading = styled.h1`
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 55px;
  color: #007360;

  ${mobileS({ fontSize: '35px', lineHeight: '40px', color: '#1d9682' })}
  ${mobileM({ fontSize: '35px', lineHeight: '40px', color: '#1d9682' })}
  @media only screen and (min-width: 600px) {
    font-family: Oswald;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 50px;
    color: #1d9682;
    padding-bottom: 20px;
  }
  @media only screen and (min-width: 600px) {
    font-size: 40px;
    line-height: 40px;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 20px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? '#315E7D' : '#000000')};
  font-family: Oswald;
  ${mobileS({ marginBottom: '15px' })}
  ${mobileM({ marginBottom: '15px' })}
  @media only screen and (min-width: 600px) {
    margin-bottom: 25px;
    font-size: 24px;
    line-height: 30px;
  }
  @media only screen and (min-width: 600px) {
    margin: 0 0 20px;
  }
`;
export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;
export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;
export const ImgWrap = styled.div`
  max-width: 450px;
  height: 100%;
  //z-index: 2;
  ${mobileS({ display: 'none' })}
  ${mobileM({
    display: 'none',
  })}
  @media only screen and (min-width: 600px) {
    height: 360px;
    max-width: 350px;
  }
  @media only screen and (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 450px;
    height: 100%;
  }
`;
export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
  ${mobileS({})}
  ${mobileM({ width: '94%' })}
  ${mobileL({ width: '93%', marginBottom: '30px', visibility: 'hidden' })}
  ${tablet({ visibility: 'visible' })}
  ${desktop({ width: '95%', margin: '7em 0 0 0' })}
  ${desktopL({ width: '100%', margin: '3em 0 0 0' })}
`;
export const ImgCont = styled.div`
  max-width: 600px;
  max-height: 95vh;
  @media screen and (min-width: 320px) {
    width: 94vw;
    margin: 2em 0 0;
    align-self: center;
  }
  @media screen and (min-width: 375px) {
    width: 88vw;
  }
  @media screen and (min-width: 425px) {
    /* max-height: 95vh; */
    max-width: 20em;
    margin-top: 3em 0;
  }
  @media screen and (min-width: 768px) {
    max-height: 95vh;
    max-width: 26em;
  }
`;
export const HeroImg = styled.img`
  width: 600px;
  @media screen and (min-width: 320px) {
    width: 100%;
  }
  @media screen and (min-width: 425px) {
    width: 100%;
  }
  @media screen and (min-width: 768px) {
    width: 100%;
  }
  @media screen and (min-width: 1024px) {
    width: 100%;
  }
`;
export const BannerBtn = styled.button`
  /* box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%); */
  /* box-shadow: 2px 4px 25px rgb(0 0 0 / 25%); */
  border-radius: 10px;
  background: transparent;
  white-space: nowrap;
  color: #1d9682;
  font-family: oswald;
  font-size: 20px;
  outline: none;
  border: 2.5px solid #1d9682;
  cursor: pointer;
  padding: 14px 40px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-align: "center";
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #2e4158;
    color: #f8f9fa;
  }
  ${mobileS({ padding: '10px 100px', fontSize: '1em', width: '100%' })}
  ${mobileM({ padding: '10px 125px' })}
  ${mobileL({ padding: '10px 150px' })}
  ${tablet({
    padding: '10px ',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
  })}
  ${desktop({ padding: '10px 50px', width: '40%' })}
`;
