import React, { useRef, useState, useEffect } from "react"
import { AiOutlineSearch } from "react-icons/ai"
import DropdownList from "../dropdownlist/DropdownList"
import Speciality from "../speciality/Speciality"
import { DropdownContainer, SpecialityDropdown } from "./DropdownStyles"

const Dropdown = ({ specialty, setSpecialty }) => {
  const [show, setShow] = useState(false)
  const listRef = useRef()

  const closeDropdown = el => {
    setSpecialty(el.specialty)
    setShow(false)
  }

  useEffect(() => {
    const handleMouseClick = e => {
      if (show && !listRef?.current?.contains(e.target)) {
        setShow(false)
      }
    }
    window.addEventListener("click", handleMouseClick)

    return () => {
      window.removeEventListener("click", handleMouseClick)
    }
  }, [show, listRef])
  return (
    <DropdownContainer>
      <SpecialityDropdown onClick={() => setShow(true)}>
        <span>{specialty}</span>
        <AiOutlineSearch />
      </SpecialityDropdown>
      <DropdownList
        show={show}
        listRef={listRef}
        closeDropdown={closeDropdown}
        setSpecialty={setSpecialty}
      />
    </DropdownContainer>
  )
}

export default Dropdown
